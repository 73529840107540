import React from "react";
import {
  Container,
  Typography,
  Box,
  Stack,
  Card,
  CardMedia,
  CardContent,
  Link,
} from "@mui/material";
import { motion } from "framer-motion";

// Logos
import app1Logo from "../assets/app1Logo.png";
import app2Logo from "../assets/app2Logo.png";
import app3Logo from "../assets/app3Logo.png";

// Store badges (use the official badges from Google/Apple if available)
import googlePlayBadge from "../assets/googlePlayBadge.png";
import appStoreBadge from "../assets/appStoreBadge.svg";

const appsData = [
  {
    id: "benefits-app",
    logo: app3Logo,
    title: "בנפיטס – ארנק ההטבות האישי שלך",
    description: `
          עם בנפיטס תוכלו ליהנות ממגוון עצום של הטבות בלעדיות:
          • הנחות באופנה ובמסעדות
          • שוברים לחגים
          • הורידו את האפליקציה, היכנסו לאזור האישי וגלו איך זה עובד.
      
          בנוסף, אנו עובדים עם חברות מובילות במשק ופיתחנו מערכת מתקדמת לרווחת העובדים,
          המעניקה לכם הטבות ייחודיות ומותאמות אישית.
      
          התחילו ליהנות מכל מה שמגיע לכם כבר עכשיו!
        `,
    // Example store links (replace with real app links)
    googlePlayLink: "https://play.google.com/store/apps/details?id=benefits",
    appStoreLink: "https://apps.apple.com/app/benefits",
  },
  {
    id: "miluim-wallet",
    logo: app1Logo,
    title: "ארנק דיגיטלי של מילואימניקים",
    description: `
      מאובטח עם גישה מהירה להטבות בלעדיות. האפליקציה הוקמה למען קהילת המילואים בישראל,
      במטרה להכיר תודה ולהעניק לכל משרת/ת גישה למגוון הטבות, הנחות, ואפשרויות ייחודיות – הכל במקום אחד.

      • גישה מהירה להטבות למילואימניקים.
      • תשלום בהנחה במגוון רשתות, כולל תווים מוזלים לרשתות מזון.
      • התאמה אישית עבור קהילת המילואים.
      • תמיכה ושירות לקוחות אישי.

      הורידו עכשיו ותחוו נוחות וחיסכון בדרך חכמה וחדשנית!
    `,
    googlePlayLink:
      "https://play.google.com/store/apps/details?id=miluimwallet",
    appStoreLink: "https://apps.apple.com/app/miluimwallet",
  },
  {
    id: "pele-benefits",
    logo: app2Logo,
    title: "פלא הטבות",
    description: `
      פלא הוא מועדון צרכנות המותאם לאוכלוסיה החרדית. החברים נהנים מתשלום בהנחה בקופה בשיעור הנחה קבוע במגוון עצום של תחומים: 
      
      • 4% הנחה ברשתות מזון.
      • 12% הנחה ברשתות אופנה ומסעדות.
      
      חיסכון אמיתי בכל קנייה, והכול ישירות מהאפליקציה!
    `,
    googlePlayLink:
      "https://play.google.com/store/apps/details?id=pelebenefits",
    appStoreLink: "https://apps.apple.com/app/pelebenefits",
  },
];

const cardVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.8, ease: "easeOut" },
  },
};

const ItsBenefitsLanding = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(135deg, #f31559 0%, #ff8fa8 100%)",
        py: 6,
        color: "#fff",
      }}
    >
      {/* Hero Section */}
      <Container maxWidth="lg" sx={{ textAlign: "center", mb: 8 }}>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h2" sx={{ fontWeight: "bold", mb: 2 }}>
            ברוכים הבאים ל-Benefits
          </Typography>
          <Typography variant="h5" sx={{ maxWidth: 600, margin: "0 auto" }}>
            אנחנו מתמחים ביצירת אפליקציות לרווחת העובדים והקהילה, ומביאים
            פתרונות דיגיטליים חדשניים לחיסכון והטבות בכל תחומי החיים.
          </Typography>
        </motion.div>
      </Container>

      {/* Apps Showcase */}
      <Container maxWidth="lg">
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={4}
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          {appsData.map((app) => (
            <motion.div
              key={app.id}
              variants={cardVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              style={{ width: "100%", maxWidth: 340 }}
            >
              <Card
                sx={{
                  backgroundColor: "#FFF5F7",
                  border: "1px solid rgba(0,0,0,0.1)",
                  borderRadius: 2,
                  color: "#333",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <CardMedia
                    component="img"
                    image={app.logo}
                    alt={app.title}
                    sx={{
                      width: "60%",
                      height: "auto",
                      margin: "20px auto 0",
                      objectFit: "contain",
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                      {app.title}
                    </Typography>
                    <Typography variant="body2" whiteSpace="pre-line">
                      {app.description.trim()}
                    </Typography>
                  </CardContent>
                </div>

                <Stack spacing={2} sx={{ pb: 2, alignItems: "center" }}>
                  <Link
                    href={app.googlePlayLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: "inline-block",
                      textDecoration: "none",
                      borderRadius: "8px",
                      backgroundColor: "#f31559",
                      padding: "10px 20px",
                      color: "#fff",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: "#d0134c",
                      },
                    }}
                  >
                    הורידו את האפליקציה
                  </Link>

                  {app.id === "benefits-app" && (
                    <Link
                      href="/support-login"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: "inline-block",
                        textDecoration: "none",
                        borderRadius: "8px",
                        backgroundColor: "#f31559",
                        padding: "10px 20px",
                        color: "#fff",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "16px",
                        transition: "background-color 0.3s",
                        "&:hover": {
                          backgroundColor: "#d0134c",
                        },
                      }}
                    >
                      פניות בנושא התחברות למערכת
                    </Link>
                  )}
                </Stack>
              </Card>
            </motion.div>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default ItsBenefitsLanding;
