import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { ANDROID_DOWNLOAD_LINK, texts } from "../assets/constants";
import Questions from "../components/Questions";
import ItBenefitsLanding from "../components/ItBenefitsLanding";

const LandingPage = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          flexDirection: "column",
          mb: 4,
        }}
      >
        <ItBenefitsLanding />
          {/* <Button
          href={ANDROID_DOWNLOAD_LINK}
          target="_blank"
          rel="noreferrer noopener"
          variant="contained"
          color="secondary"
          sx={{
              borderRadius: "40px",
          }}
      >
          {texts.welcome.button}
      </Button> */}
        {/* <Questions /> */}

      </Box>
    </>
  );
};

export default LandingPage;
