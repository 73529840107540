import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";

interface ScreenElement {
  id: string;
  type: "HEADER" | "PARAGRAPH";
  text: string;
  order: number;
}

const BOT_CONFIG: Record<string, number> = {
  pele: 355,
  benefits: 420,
  miluimnikim: 410,
  wincoins: 327,
};

const TermsScreen = () => {
  const { appName } = useParams();
  const botId = BOT_CONFIG[appName || ""] || null;

  const [elements, setElements] = useState<ScreenElement[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!botId) {
      setError("Invalid app name.");
      setLoading(false);
      return;
    }

    const fetchTerms = async () => {
      try {
        const response = await axios.get(
          "https://api.benefits.co.il/cms/screen-elements",
          {
            params: { botId, appType: "APP", screenName: "TERMS" },
          }
        );

        const sortedData = response.data.sort(
          (a: ScreenElement, b: ScreenElement) => a.order - b.order
        );

        setElements(sortedData);
      } catch (err) {
        setError("Failed to load terms and conditions.");
      } finally {
        setLoading(false);
      }
    };

    fetchTerms();
  }, [botId]);

  // Memoized elements for performance optimization
  const sortedElements = useMemo(() => elements, [elements]);

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ textAlign: "center", mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Toolbar />
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Toolbar />
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          backgroundColor: "white",
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", textAlign: "center", color: "black" }}
          mb={3}
        >
          תנאי השימוש
        </Typography>

        <Stack spacing={3}>
          {sortedElements.map((element: ScreenElement) => (
            <div key={element.id}>
              {element.type === "HEADER" ? (
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "black" }}
                >
                  {element.text}
                </Typography>
              ) : (
                <Typography sx={{ color: "black", fontSize: 16 }}>
                  {element.text}
                </Typography>
              )}
            </div>
          ))}
        </Stack>
      </Paper>
    </Container>
  );
};

export default TermsScreen;
