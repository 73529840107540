import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyC_CJ35UYs8Evx21kbRYnHuts3tRH_Vtk4",
  authDomain: "benefitssite-e3815.firebaseapp.com",
  projectId: "benefitssite-e3815",
  storageBucket: "benefitssite-e3815.firebasestorage.app",
  messagingSenderId: "157391457004",
  appId: "1:157391457004:web:ce9573ae63bee3a5a573c9",
  measurementId: "G-DZLYXBCSXK",
};

import theme from "./theme";
import LandingPage from "./pages/LandingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DownloadPage from "./pages/DownloadPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

const Layout = () => (
  <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <ScrollToTop />
    <CssBaseline />
    <Header />
    <Outlet />
    <Footer />
  </div>
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "privacy/:appName",
        element: <PrivacyPolicy />,
      },
      {
        path: "download",
        element: <DownloadPage />,
      },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
]);

const cacheRtl = createCache({
  key: "muirtl",
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore known issue with mui rtl
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
