export const texts = {
  welcome: {
    title: "וואו, איזה פלא!",
    subtitle: "הירשם כדי לקבל גישה לפלא",
    button: "להורדה לחצו כאן!",
  },
  welcomeNew: {
    text1: "בלעדי ללקוחות נתיב מובייל רוצים לחסוך בקניות?",
    text2: "הכירו את 'פלא'",
    text3: "אפליקציית ההטבות החדשה לציבור החרדי",
    text4: "מהיום תוכלו לחסוך בכל קניה",
    text5:
      "הנחות קופה כולל כפל מבצעים במעמד הקניה ברשתות אופנה והנעלה, הנחות ברשתות מזון (רמי לוי, שוק מהדרין קארפור), במגוון מסעדות (מהדרין), מוצרים, אטרקציות ועוד...",
    text6: "בואו להרגיש את הפלא",
    text7: "הורידו את האפליקציה ותתחילו לחסוך אלפי שקלים בשנה",
  },
  questions: {
    text1: "בלעדי ללקוחות נתיב מובייל!",

    text2: "300 מטבעות פלא מתנת הצטרפות",
    text3: "בכל חודש, 49 מטבעות פלא מתנה ללקוח",
    text4: "צריכים עזרה? יש לכם שאלה?",
    text5: "תרשמו לנו הודעה בווטצאפ במספר",
    text6: "רוצים לחסוך בקניות הכירו את פלא",
    phone: "055-2775359",
  },
  form: {
    fields: {
      firstName: "שם",
      lastName: "שם משפחה",
      phone: "טלפון",
      email: "אימייל",
      birthDate: "תאריך לידה",
    },
    errors: {
      firstName: "שם פרטי נדרש",
      lastName: "שם משפחה נדרש",
      phone: "מספר טלפון נדרש",
      phoneLength: "מספר הטלפון קצר מידי",
      email: 'כתובת דוא"ל נדרשת',
      privacyPolicy: "בבקשה, קבל את מדיניות הפרטיות",
    },
    checkboxes: {
      privacyPolicy: "אני מאשר/ת את ",
      privacyPolicyLink: "התקנון.",
      advertising: "אני מאשר לקבל מידע פרסומי.",
    },
    button: "שלח",
    successMessage: "המידע נשלח בהצלחה! תקבל הודעת SMS עם כל המידע הדרוש.",
    resendButton: "שלח עוד אחד",
  },
  merchants: {
    title: "הרשתות באפליקציה:",
    subtitle: "התקן את האפליקציה והתחל לחסוך אלפי שקלים בשנה",
  },
  footer: {
    privacy: "תקנון",
    copyright: "© 2025 בנפיטס",
  },
  privacyPolicy: {
    title: "תקנון, תנאי שימוש ומדיניות הפרטיות",
    text: [
      {
        title: "כללי",
        children: [
          {
            text: "הוראות התקנון מנוסחות בלשון זכר לצרכי נוחות בלבד ומתייחסות לנשים וגברים כאחד",
          },
          {
            text: 'האתר מנוהל ומופעל ע"י בנפיטס מועדוני צרכנות בע"מ (להלן: "מנהל האתר").',
          },
        ],
      },
      {
        title: "הצטרפות (הורדת) אפליקציית ארנק ההטבות",
        children: [
          {
            text: 'כל אדם שמלאו לו 18 שנים, רשאי לבקש להצטרף למועדון הלקוחות ואפליקציית ההטבות פלא (להלן: "פלא").',
          },
          {
            text: "מובהר, כי ההצטרפות למועדון וקבלת הטבות המועדון, הינם ללקוחות פרטיים ולרווחתם.",
          },
          {
            text: "כל אדם המעוניין להיות חבר המועדון, מוזמן להוריד ולהשתמש באפליקציה של פלא.",
          },
        ],
      },
      {
        title: 'צבירת מטבעות  פלא  ע"פ תכנית ה- cashback',
        children: [
          {
            text: "מחזיקי ארנק ההטבות יזוכו במטבעות בעבור כל רכישה מחברת נתיב מובייל, כך שבכל רכישה, תזוכה יתרת המטבעות באפליקציית ההטבות בסכום הרכישה המלא. \n לדוגמא: ברכישה בסך של 39 ₪, יצבור המשתמש 39 מטבעות.",
          },
          {
            text: "תוקף המטבעות הינו למשך 12 חודשים.",
          },
          {
            text: "מנהל האתר רשאי לשנות את השיטה לצבירת מטבעות ו/או מימושם ו/או לבטלם, על פי שיקול דעתו הבלעדית.",
          },
          {
            text: "מנהל האתר יהיה רשאי לעשות שימוש בתשתית הטכנולוגית של המועדון לצורך מתן הטבות / מענקים / זכאויות לקבוצות לקוחות על פי שיקול דעתו הבלעדי.",
          },
        ],
      },
      {
        title: "המרה/מימוש ויתרת מטבעות",
        children: [
          {
            text: "מנהל האתר יאפשר לחברי המועדון להמיר מטבעות שנצברו לזכותם ולקבל כנגדם הנחות במגוון רשתות ו/או הנחות בקניית שוברים שונים, כפי שיעודכן באפליקציה מעת לעת. ",
          },
          {
            text: "יובהר כי, למנהל האתר אין התחייבות כלשהי לפדות את יתרת המטבעות במזומן.",
          },
          {
            text: "מימוש המטבעות הינו בכפוף לתנאים הבאים:",
            children: [
              "לצורך מימוש המטבעות, נדרשת פעולה אקטיבית של החבר באמצעות האפליקציה או האתר.",
              "החבר יידרש להזדהות בהתאם לדרישות הזיהוי של מנהל האתר כפי שיהיו מעת לעת.",
              'מימוש מטבעות בבתי העסק, תתבצע מתוך אזור ה"תשלום בהנחה" הנמצא באפליקציה. \n בלחיצה על כפתור "תשלום בבית עסק" (באזור "תשלום בהנחה"), יופיע מחשבון בו יידרש החבר להקליד את הסכום המלא של העסקה, ולאשר לתשלום את הסכום (המופחת) שיופיע לאחר ניכוי המטבעות. \n את הקוד/ברקוד המתקבל יציג החבר לקופאי/ת.',
              'מימוש המטבעות אפשרי ברשימת הרשתות המפורטות, תחת הכפתורים "מסעדות" ו/או "אופנה".',
              "זהות הרשתות עשויה להשתנות ללא הודעה מוקדמת.",
            ],
          },
        ],
      },
      {
        title: "עדכון פרטים אישיים",
        children: [
          {
            text: 'בהצטרפות לאתר מאשר החבר כי המידע שנמסר על ידו ו/או ייאסף אודותיו (להלן: "המידע"), יישמר במאגרי המידע של מנהל האתר לצורך ייעול השירות והקשר עמו, לרבות לטובת צרכים תפעוליים, שיווקיים ודיוור ישיר.',
          },
          {
            text: "יובהר כי לא חלה על החבר חובה חוקית למסור את המידע ומסירתו הינה מרצונו ובהסכמתו.",
          },
          {
            text: "מנהל האתר יהיה רשאי להעביר את המידע לצדדים שלישיים (ובלבד שיתחייבו לשמור על פרטי החבר האישיים כנדרש על -פי דין), הנותנים שירות למנהל האתר, לצורך תפעול ההסדרים הנוגעים למועדון כמפורט בתקנון זה. \n מנהל האתר רשאי להסתייע בשירותים של ספקים חיצוניים באספקת שירות לחבר, אשר יתכן שתהיה גם להם גישה למידע בסיסי אודות החבר.",
          },
          {
            text: "בהתאם להסכמת החבר כדין, מנהל האתר יהיה רשאי לפנות אל החבר בדיוור ישיר באמצעות דואר אלקטרוני, מסרונים (SMS), רשתות חברתיות, ובטלפון, וזאת כל עוד לא נתקבלה הודעה אחרת מאת החבר בכתב.",
          },
          {
            text: "במסגרת ההרשמה יידרש החבר למסור מידע אישי לגביו, כדוגמת שם מלא, דרכי ההתקשרות עמו והכל בהתאם לתנאי השימוש של האתר או האפליקציה.",
          },
          {
            text: "באחריות החבר לדווח למנהל האתר בדבר שינוי בפרטיו האישיים. מנהל האתר לא יהיה אחראי לאי קבלת הטבה ו/או כל עדכון בקשר עם האתר או האפליקציה הנובע מאי עדכון פרטיו האישיים של החבר במועד.",
          },
          {
            text: "החבר מסכים בזאת באופן מפורש לשימוש במידע כאמור לעיל ומאשר כי השימוש בו לא ייחשב כפגיעה בפרטיותו ולא יזכה את החבר בכל סעד ו/או פיצוי. \n החבר רשאי לבטל את הסכמתו זו על ידי מתן הודעה בכתב למנהל האתר.",
          },
        ],
      },
      {
        title: "ביטול חברות באתר/אפליקציה",
        children: [
          {
            text: "מנהל האתר יהא רשאי לבטל חברותו של חבר/משתמש, מכל סיבה שהיא מבלי שתהא לו חובה לנמק את סיבת הביטול ולרבות במידה והחבר לא עמד בהתחייבויותיו כלפי מנהל האתר.",
          },
          {
            text: "מנהל האתר יוכל להודיע בכל דרך שימצאו לנכון על הפסקת פעילות האתר בהודעה כנדרש על פי דין. \n במקרה של הפסקת פעילות האתר כאמור, תינתן לחבר האפשרות לממש מטבעות שנצברו בהתאם להוראות תקנון זה, למשך תקופה הנדרשת על פי דין.",
          },
        ],
      },
      {
        title: "אבטחת מידע והגנת הפרטיות",
        children: [
          {
            text: 'מנהל האתר ישתמש באמצעי הגנה והצפנה SSL הנהוגים בתחום הסחר האלקטרוני בישראל ובארה"ב לפעולות של מסירת מידע מקוון, ובהתאם לנדרש על פי כל דין. אמצעי ההגנה האמור נועדו לאבטחת זרימת מידע מוצפנת בין מחשב חבר המועדון בזמן ביצוע הרכישה לבין שרתי המערכת.',
          },
          {
            text: 'מנהל האתר ינהג במידע ובפרטים האישיים שנמסרו במסגרת השימוש באתר בהתאם ובכפוף להוראות כל דין. מבלי לגרוע מן האמור, מנהל האתר יהא רשאי להשתמש בפרטים האישיים ובמידע שמסרו החברים כאמור וכן לאסוף מידע אודות דפוסי השימוש שלהם באתר ע"י רישום השימוש שיעשו באתר (לרבות באמצעות cookies) בכפוף לכל דין, במקרים הבאים:',
            children: [
              "לצורך העברתם לספקי התוכן על מנת להשלים את פעולת הרכישה שביצעו החברים באתר.",
              "לצורך שיפור המידע והתכנים שמנהל האתר יציע במסגרת האתר לחברים באופן אישי ו/או לכלל ו/או לחלק מהחברים באתר.",
              "על מנת לשלוח לחברים, מעת לעת, תכנים ו/או ליידע אותם בדבר מוצרים ושירותים שונים העשויים לעניין אותם והנמכרים על ידי מנהל האתר ו/או על ידי ספקי התוכן.",
              "לצורך ניתוח מידע סטטיסטי על ידי מנהל האתר ו/או מסירתו לצדדים שלישיים לרבות למפרסמים ובלבד שמידע כאמור לא יזהה את החברים אישית באופן שמי.",
              "לצורך יצירת קשר עם החברים במקרה הצורך.",
              "לצורך תחזוקת האתר ו/או המערכת.",
            ],
          },
          {
            text: "ידוע לחברים כי מנהל האתר מקדיש משאבים ונוקט אמצעים מחמירים למניעת חדירה לאתר ולסיכול פגיעה אפשרית בפרטיותם, אולם הוא אינו יכול למנוע שיבושים באתר באופן מוחלט.",
          },
        ],
      },
      {
        title: "כללי",
        children: [
          {
            text: "למנהל האתר נשמרת הזכות להוסיף, לשנות ולהחליף בכל עת את תנאי התקנון, ללא הודעה מוקדמת ועל פי שיקול דעתו הבלעדי והמוחלט, ובכלל זאת הזכות לפרש את הוראותיו, בכפוף לדין. \n החל ממועד השינוי של התקנון יחייב את האתר נוסחו החדש בלבד. \nנוסחו המחייב של התקנון הוא זה שיופיע באתר/אפליקציה בלבד.",
          },
          {
            text: "מנהל האתר יהא רשאי לשנות את שם המועדון, סמליו וכד' בכל עת לפי שיקול דעתו הבלעדי בלבד.",
          },
          {
            text: "מתן הטבות לחברים הינם בכפוף לפעילות תקינה של מערכות התפעול והמחשוב של מנהל האתר וצדדים שלישיים רלוונטיים.",
          },
          {
            text: "במידה ויחולו תקלות אשר ימנעו באופן זמני את מתן ההטבות כאמור לעיל, יפעל מנהל האתר לטיפול בתקלות בהקדם ולא תהיה לחברים טענות או תביעות כנגד מנהל האתר בשל אי מתן ההטבות בתקופת התקלות והטיפול בהן.",
          },
          {
            text: "לחבר לא תהיה כל דרישה ו/או תביעה בקשר עם התקנון ו/או שינוי התקנון.",
          },
        ],
      },
    ],
  },
  download: {
    text: "הורד והשתמש באפליקציה שלנו.",
  },
}

type LabelColor =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"

type IButton = {
  id: number
  text: string
  href: string
  color?: LabelColor
}

export const ANDROID_DOWNLOAD_LINK =
  "https://play.google.com/store/apps/details?id=com.pele.app"

export const DOWNLOAD_BUTTONS: IButton[] = [
  {
    id: 1,
    text: "Download Android",
    href: ANDROID_DOWNLOAD_LINK,
    color: "primary",
  },

  {
    id: 2,
    text: "WebApp",
    href: "https://pelegift.co.il/",
    color: "secondary",
  },
]
