import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Logo from "./Logo";

const Header = () => {
  return (
    <AppBar color="transparent" elevation={0} position="absolute">
      <Toolbar>
        {/* <Logo /> */}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
